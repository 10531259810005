import jsonpP from "jsonp-p";

const getLastLookup = () => JSON.parse(sessionStorage.getItem("last_lookup"));

const lookupRequesterIp = () => {
  const lastLookup = getLastLookup();
  if (!lastLookup || Date.now() - lastLookup.timestamp > 300000) {
    return jsonpP(
      `https://api.map.baidu.com/location/ip?ak=${process.env.GATSBY_BAIDU_ACCESS_KEY}`,
      null
    ).promise.then(data => {
      if (data.status === 0 || data.status === 1) {
        const result = {
          data,
          timestamp: Date.now()
        };
        sessionStorage.setItem("last_lookup", JSON.stringify(result));
        return result;
      }
      throw new Error(data.message);
    });
  }
  return Promise.resolve(getLastLookup());
};

export { lookupRequesterIp as default };
